import React, { useState, useContext, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { path } from 'ramda';
import { Trivia } from '../../_components';
import { ButtonYellow, Badge } from '../../_elements';
import { breakpoints } from '../../_helpers/breakpoints';
import { ContentContext } from '../../_context';
import { HiArrowRight } from "react-icons/hi";
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .trivia_endend{
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    padding-top: 35px;
  }
  .loader{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-top: 20px;
    svg{
      color: #FFC600;
    }
  }

  .title_tab{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    >p{
      font-size: 15px;
      line-height: 15px;
      margin: 0;
      padding: 0;
      padding-top: 1px;
    }
  }
  .MuiTab-root{
    color: #212529!important;
    .Mui-selected{
      color: #212529!important;
      font-weight: 600;
    }
  }
  .MuiTabs-indicator{
    background-color: #FFC600!important;
  }
  .tabs{
    width: 100%;
    //background-color: pink;
    .tab{
      width: 50%;
      max-width: 50%!important;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .elements{
    margin-top: 15px;
    .element{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 20px;
      border: 1px solid #EAEAF0;
      border-bottom: 3px solid #EAEAF0;
      border-radius: 12px;
      cursor: not-allowed;
      margin-bottom: 15px;
      :last-child{
        margin-bottom: 0;
      }
      .left{
        display: flex;
        flex-direction: row;
        width: calc(100% - 35px);
        .image{
          display: flex;
          width: 83px;
          height: 83px;
          margin-right: 15px;
          border-radius: 6px;
          overflow: hidden;
          border: 1px solid #EAEAF0;
          img{
            float: left;
            width: 100%;
          }
        }
        .data{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: calc(100% - 83px);
          .title{
            font-size: 16px;
            line-height: 16px;
            font-weight: 600;
          }
          .label_status{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            border-radius: 4px;
            color: #FFFFFF;
            font-size: 13px;
            line-height: 13px;
            font-weight: 800;
            margin-top: 10px;
            .score{
              color: #FFC600;
              margin-left: 10px;
            }
            .retry,
            .pending,
            .viewed,
            .approved,
            .blocked{
              padding: 5px 7px;
              box-sizing: border-box;
              border-radius: 6px;
              margin-right: 0;
              /* margin-bottom: 2vw; */
            }
            .retry{
              border: 2px solid #C5000D;
              background-color: #C5000D;
            }
            .pending{
              border: 2px solid #616161;
              background-color: #616161;
            }
            .viewed{
              border: 2px solid #FFC600;
              background-color: #FFC600;
            }
            .approved{
              border: 2px solid #FFC600;
              background-color: #FFC600;
            }
            .blocked{
              color: #C5000D;
              border: 2px solid #C5000D;
              background-color: transparent;
              margin-right: 0;
            }
          }
        }
      }
      .right{
        .icon_arrow{
          display: flex;
          /* width: 25px; */
          width: 35px;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          margin-left: auto;
          font-size: 20px;
          color: #212529;


        }
      }
      &.active{
        cursor: pointer;
      }
      &.active:hover{
        border-bottom: 3px solid #FFC600;
        .right{
          .icon_arrow{
            color: #FFC600;
          }
        }
      }
    }
  }

  .evaluation_question_tabs{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .question_tab{
      height: 2px;
      width: ${props => props.questions_total ? `calc(100% / ${props.questions_total} - 10px)` : "0"};
      background-color: #BFBFBF;
      &.active{
        background-color: #FFC600;
      }
      &.correct{
        background-color: green;
      }
      &.incorrect{
        background-color: red;
      }
    }
  }

  .box_ranking{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    
    .own_ranking_data{
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 5vw 5vw;
      width: 100%;
      border: 2px dashed #FFC600;
      background-color: rgba(255,198,0,0.025);
      margin-bottom: 5vw;
      border-radius: 12px;
      .own_points,
      .own_position{
        display: flex;
        flex-direction: column;
        align-items: center;
        .label{
          font-size: 3.5vw;
          line-height: 3.5vw;
          font-weight: 600;
        }
        .value{
          font-size: 11vw;
          line-height: 11vw;
          font-weight: 800;
          margin-top: 2vw;
          color: #FFC600;
        }
      } 
      .own_points{
        margin-right: 45px;
      }
      .own_position{

      }
    }
    .top{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      align-self: center;
      justify-content: space-between;
      width: 100%;
      .position_1,
      .position_2,
      .position_3{
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        .name{
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          margin-top: 5px;
          padding-left: 5px;
          padding-right: 5px;
          text-align: center;
        }
        .icon{
          width: 25vw;
          img{
            float: left;
            width: 100%;
          }
        }
        .base{
          width: 11vw;
          //height: 11vw;
          background-color: #FFC600;
          margin-top: 2vw;
        }
        .avatar{
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 9vw;
          overflow: hidden;
          width: 18vw;
          height: 18vw;
          margin-top: 2vw;
          border: 1px solid #EAEAF0;
          img{
            float: left;
            width: 100%;
          }
        }
      }
      .position_1{
        .base{
          height: 70px;
        }
      }
      .position_2{
        .base{
          height: 40px;
        }
      }
      .position_3{
        .base{
          height: 20px;
        }
      }
    }
    .list_ranking{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 25px;
      .items{
        display: flex;
        flex-direction: column;
        width: 100%;
        .item{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          border: 1px solid #FFFFFF;
          border-bottom: 1px solid #EAEAF0;
          box-sizing: border-box;
          padding: 20px 20px;
          border-radius: 12px;
          margin-bottom: 10px;
          &.is_own{
            border: 2px dashed #FFC600;
            background-color: rgba(255, 198, 0, 0.025);
          }
          .left{
            display: flex;
            flex-direction: column;
            justify-content: center;
            .own{
              font-size: 18px;
              line-height: 22px;
              font-weight: 700;
              color: #FFC600;
              margin-top: 7px;
            }
            .points{
              font-weight: 700;
              font-size: 28px;
              line-height: 28px;
              color: #556377;
            }
            .name{
              font-size: 18px;
              line-height: 22px;
              font-weight: 400;
              color: #252525;
              margin-top: 7px;
            }

          }
          .right{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .position{
              font-size: 20px;
              line-height: 25px;
              font-weight: 600;
              color: #DA291C;
              /*margin-top: 7px;*/
            }
            .avatar{
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 40px;
              border: 1px solid #EAEAF0;
              overflow: hidden;
              width: 80px;
              height: 80px;
              /*margin-top: 10px;*/
              margin-left: 0;
              img{
                float: left;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  @media ${breakpoints.xs} {
    .box_ranking{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 20px;
      .own_ranking_data{
        padding: 25px 25px;
        width: 450px;
        margin-bottom: 25px;
        border-radius: 12px;
        .own_points,
        .own_position{
          .label{
            font-size: 18px;
            line-height: 18px;
          }
          .value{
            font-size: 55px;
            line-height: 55px;
            margin-top: 10px;
          }
        } 
        .own_points{
          margin-right: 45px;
        }
        .own_position{

        }
      }
      .top{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        align-self: center;
        justify-content: space-between;
        width: 450px;
        .position_1,
        .position_2,
        .position_3{
          display: flex;
          flex-direction: column;
          /* justify-content: center; */
          align-items: center;
          .icon{
            width: 120px;
            img{
              float: left;
              width: 100%;
            }
          }
          .base{
            width: 40px;
            height: 40px;
            background-color: #FFC600;
            margin-top: 10px;
          }
          .avatar{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 40px;
            overflow: hidden;
            width: 80px;
            height: 80px;
            margin-top: 10px;
            border: 1px solid #EAEAF0;
            img{
              float: left;
              width: 100%;
            }
          }
        }
        .position_1{
          .base{
            height: 70px;
          }
        }
        .position_2{
          .base{
            height: 40px;
          }
        }
        .position_3{
          .base{
            height: 20px;
          }
        }
      }
      .list_ranking{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 25px;
        .items{
          display: flex;
          flex-direction: column;
          width: 100%;
          .item{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            border: 1px solid #FFFFFF;
            border-bottom: 1px solid #EAEAF0;
            box-sizing: border-box;
            padding: 20px 20px;
            border-radius: 12px;
            margin-bottom: 10px;
            &.is_own{
              border: 2px dashed #FFC600;
              background-color: rgba(255, 198, 0, 0.025);
            }
            .left{
              display: flex;
              flex-direction: column;
              justify-content: center;
              .own{
                font-size: 18px;
                line-height: 22px;
                font-weight: 700;
                color: #FFC600;
                margin-top: 7px;
              }
              .points{
                font-weight: 700;
                font-size: 28px;
                line-height: 28px;
                color: #556377;
              }
              .name{
                font-size: 18px;
                line-height: 22px;
                font-weight: 400;
                color: #252525;
                margin-top: 7px;
              }

            }
            .right{
              display: flex;
              flex-direction: row;
              align-items: center;
              .position{
                font-size: 20px;
                line-height: 25px;
                font-weight: 600;
                color: #DA291C;
                /*margin-top: 7px;*/
              }
              .avatar{
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 40px;
                border: 1px solid #EAEAF0;
                overflow: hidden;
                width: 80px;
                height: 80px;
                /*margin-top: 10px;*/
                margin-left: 25px;
                img{
                  float: left;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

  }
  @media ${breakpoints.sm} {

  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`
const ModalStyled = styled(Modal)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  @media ${breakpoints.xs} {
  }
  @media ${breakpoints.sm} {
  }
  @media ${breakpoints.md} {

  }
  @media ${breakpoints.lg} {

  }
`

function CustomTabPanel(props) {
  const { children,
          value,
          index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const TriviaContainer = (props) => {

  const { history, location } = props;
  const [ triviaClose, setTriviaClose ] = useState(true);

  const { userData,
          triviaGame,
          setTriviaGame,
          getTriviaGame,
          trivia,
          setTrivia,
          getTrivia,
          triviaRanking } = useContext(ContentContext);

  const [ value, setValue ] = useState(0);
  const [ evaluation, setEvaluation ] = useState(null);
  const [ evaluationOpen, setEvaluationOpen ] = useState(false);
  const [ triviaGameSelectedId, setTriviaGameSelectedId ] = useState(false);
  const [ triviaSelected, setTriviaSelected ] = useState(null);
  const [ evaluationData, setEvaluationData ] = useState(null);
  const [ loadingRanking, setLoadingRanking ] = useState(null);

  const [ firstPlace, setFirstPlace ] = useState(null);
  const [ secondPlace, setSecondPlace ] = useState(null);
  const [ thirdPlace, setThirdPlace ] = useState(null);
  
  const handleChange = (event, newValue) => {
    console.log("newValue", newValue)
    if(newValue === 1){
      setLoadingRanking(true);
      getTrivia();
    }
    setValue(newValue);
  };
  
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setEvaluationOpen(false)
    }
  }

  const handleGetGame = (trivia) => {
    setEvaluationOpen(true);
    setEvaluationData({type: "trivia", id: trivia._id});
    setTriviaSelected(trivia)
    setTriviaGameSelectedId(trivia._id);
    //getTriviaGame(trivia._id);
  }

  useEffect(() => {

    setLoadingRanking(true);
    getTrivia();

    return () => {}
  }, [])

  useEffect(() => {

    if(triviaRanking){

      handleRankingData();

    }

    return () => {}
  }, [triviaRanking])

  const handleRankingData = () => {
    
    setLoadingRanking(false);
    const position_1 = triviaRanking.first_places.filter(ranking => ranking.positions === 1);
    const position_2 = triviaRanking.first_places.filter(ranking => ranking.positions === 2);
    const position_3 = triviaRanking.first_places.filter(ranking => ranking.positions === 3);

    if(position_1.length){
      setFirstPlace(position_1[0]);
    }
    if(position_2.length){
      setSecondPlace(position_2[0]);
    }
    if(position_3.length){
      setThirdPlace(position_3[0]);
    }

  }

  useEffect(() => {

    console.log("firstPlace", firstPlace);

    return () => {}
  }, [firstPlace])

  
  useEffect(() => {

    if(triviaGame){
      setEvaluation({evaluation: triviaGame}/*path(['data', 'single', 'trivia'], triviaGame)*/);
      setEvaluationData({type: "trivia", id: triviaGame._id});
      setEvaluationOpen(true);
    }

    return () => {}
  }, [triviaGame])



  return (
    <StyledWrapper>

      <ModalStyled
        open={evaluationOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby  ="modal-modal-description"
        //onBackdropClick={(event) => {event.stopPropagation(); return false;}}
        disableEscapeKeyDown
      >
        <Fragment>
          {evaluationData &&
              <Trivia
                _id={triviaGameSelectedId}
                scope={"trivia"}
                title={triviaSelected.name}
                parentData={triviaSelected}
                evaluation={evaluation}
                setEvaluation={setEvaluation}
                evaluationData={evaluationData}
                setEvaluationData={setEvaluationData}
                handleClose={handleClose}
              />
          }
        </Fragment>
      </ModalStyled>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tabs'>
            <Tab label={<div className='title_tab'><p>Trivia</p></div>} className='tab' />
            <Tab label={<div className='title_tab'><p>Ranking</p></div>} className='tab' />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} className='panel_tab'>
          <div className="elements">
            {trivia ?
              trivia.length ?
              trivia.map((element, index) => {
              return(
                <div className={`element ${element.status === 0 ? "active" : (element.one_shot === false) ? "active" : ""}`} onClick={() => element.status === 0 ? handleGetGame(element) : (element.one_shot === false) ? handleGetGame(element) : null }>
                  <div className='left'>
                    <div className='image'>
                      <img src={element.image} />
                    </div>
                    <div className='data'>
                      <div className='title'>{element.name}</div>
                      <div className='description'>{element.description}</div>
                      <div className='label_status'>
                        {element.status === 0 ? <div className='pending'>PENDIENTE</div> : ''}
                        {(element.status !== 0 /*&& element.one_shot === true*/) ? <div className='approved'>REALIZADO</div> : ''}
                        {/* {element.score ? <div className='score'>{element?.score} puntos</div> : ""} */}
                      </div>
                    </div>
                  </div>
                  <div className='right'>
                    <div className='icon_arrow'>
                      <HiArrowRight />
                    </div>
                  </div>
                </div>
              )
            }) : <div className="trivia_endend">¡ExperTrivia no se encuentra disponible!</div> : <div className='loader'><CircularProgress /></div>}

          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} className='panel_tab'>
          <div className='box_ranking'>
            {!loadingRanking ?
            <>
            {triviaRanking?.last_positions && triviaRanking?.last_positions.map((pos, index) => {
              if(pos.user_position){
                return (
                  <div className='own_ranking_data'>
                  <div className='own_points'>
                    <div className='label'>Tus puntos:</div> 
                    <div className='value'>{pos.score}</div>
                  </div>
                  <div className='own_position'>
                    <div className='label'>Tu puesto:</div> 
                    <div className='value'>{pos.positions}</div>
                  </div>
                </div>
                )
              }
            })}

            <div className='top'>

              <div className='position_2'>
                <div className='icon'>
                  <img src="images/trivia_position_2.svg" />
                </div>
                <div className='base'></div>
                <div className='avatar'>
                  {secondPlace && secondPlace.image ?
                    <img src={secondPlace.image} alt="" />
                  : 
                    <img src="/images/noavatar.jpg" />
                  }
                </div>
                <div className='name'>{secondPlace?.name}</div>
              </div>
                
              <div className='position_1'>
                <div className='icon'>
                  <img src="images/trivia_position_1.svg" />
                </div>
                <div className='base'></div>
                <div className='avatar'>
                  {firstPlace && firstPlace.image ?
                    <img src={firstPlace.image} alt="" />
                  :
                    <img src="/images/noavatar.jpg" />
                  }
                </div>
                <div className='name'>{firstPlace?.name}</div>
              </div>

              <div className='position_3'>
                <div className='icon'>
                  <img src="images/trivia_position_3.svg" />
                </div>
                <div className='base'></div>
                <div className='avatar'>
                  {thirdPlace && thirdPlace.image ?
                    <img src={thirdPlace.image} alt="" />
                  :
                    <img src="/images/noavatar.jpg" />
                  }
                </div>
                <div className='name'>{thirdPlace?.name}</div>
              </div>

            </div>

            <div className='list_ranking'>
              <div className='items'>
                {triviaRanking?.last_positions && triviaRanking?.last_positions.map((pos, index) => {
                  return(
                    <div className={`item${pos.user_position ? ' is_own' : ''}`}>
                      <div className='left'>
                        <div className='points'>{pos.score} pts</div>
                        <div className='name'>{pos.name}</div>
                        {pos.user_position ? <div className='own'>¡Tu estas aqui!</div> : ''}
                      </div>
                      <div className='right'>
                        <div className='position'>Puesto {pos.positions}º</div>
                        <div className='avatar'>
                          {pos.image ?
                            <img src="/images/noavatar.jpg" />
                            // <img src={pos.image} alt="" />
                          :
                            <img src="/images/noavatar.jpg" />
                          }
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            </>
            :
            <div className='loader'>
              <CircularProgress />
            </div>
            }
          </div>
        </CustomTabPanel>
      </Box>

    </StyledWrapper>
  )
}

export default TriviaContainer;
