import React, { useState, useContext, useEffect, Fragment, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../_helpers/breakpoints';
import { useHistory, useLocation } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { AiOutlineLink } from "react-icons/ai";
import { RxExternalLink } from "react-icons/rx";
import { getCoursePath } from '~/_helpers/commons';
import { MdDownloadForOffline } from "react-icons/md";
import { MdOutlineDownloadForOffline } from "react-icons/md";

import { ContentContext,
         AuthContext } from '../../_context';

const WrapperStyled = styled.div`
  margin-left: 15px;
  .icon{
    display: flex;
    font-size: 25px;
    line-height: 25px;
    cursor: pointer;
  }
  :hover{
    .icon{
      color: #DD1D21;
      fill: #DD1D21;
    }
  }
  @media ${breakpoints.xs} {//576px
    //margin-left: 5px;
  }
  @media ${breakpoints.sm} {//768px
  }
  @media ${breakpoints.md} {//992px
  }
  @media ${breakpoints.lg} {//1200px

  }
  @media ${breakpoints.xl} {//1366px

  }
  @media ${breakpoints.xxl} {//1440px
    //margin-left: 15px;
  }
  @media ${breakpoints.xxxl} {//1680px

  }
  @media ${breakpoints.xxxxl} {//1920px
  }

`


const Goto = (props) => {

  let history = useHistory();
  let location = useLocation();

  const { campus,
          gotoData,
          setGotoData,
          setCourse } = useContext(ContentContext);

  const { setActiveRoute } = useContext(AuthContext);

  const { go_to,
          className } = props;

  const [ icon, setIcon ] = useState(<RxExternalLink />)

  /*
  useEffect(() => {

    console.log("go_to", go_to)

    return () => {}
  }, [go_to])
  */
  
  const handleClicGoto = () => {

    // getCoursePath(campus, go_to.id_content);
    // return null;
    
    if(go_to.type === "interno"){

      setGotoData({path: go_to.path, _id: go_to.id_content});

      if(go_to.path === "course"){
        setCourse(null);
        setActiveRoute("campus");
        history.push(`/campus/course/${go_to.id_content}`);
      }else{
        if(go_to.path === "catalogue"){
          history.push(`/catalogo`);
        }else{
          if(go_to.path === "categories"){
            setActiveRoute("catalogo");
            history.push(`/catalogo/${go_to.id_content}`);
          }else{
            setCourse(null);
            setActiveRoute("campus");
            history.push(`/campus/module/${go_to.id_content}`);
          }
        }
      }
      
    }

    if(go_to.type === "externo"){
      window.open(go_to.path, '_blank').focus();
    }

  }

  const handleLinkType = (path) => {
    console.log("path", path)
  }

  useEffect(() => {

    // Lista de extensiones de archivo comunes
    const fileExtensions = /\.(pdf|doc|docx|xls|xlsx|ppt|pptx|txt|zip|rar|mp3|mp4|jpg|jpeg|png|gif)$/i;

    try {
      const urlObj = new URL(go_to.path);
      const path = urlObj.pathname;

      if (fileExtensions.test(path)) {
        setIcon(<MdDownloadForOffline />);
        //return `La URL ${url} apunta a un archivo.`;
      } else {
        setIcon(<RxExternalLink />);
        //return `La URL ${url} apunta a una página web.`;
      }
    } catch (error) {
      setIcon(<RxExternalLink />);
      //return `URL inválida: ${url}`;
    }

    console.log("handleLinkType", go_to);//handleLinkType()
  
    return () => {}
  }, [])
  

  return (

    <WrapperStyled
      className={className}
      onClick={handleClicGoto}
    >
      <div className='icon'>
        {icon}
      </div>

    </WrapperStyled>

  )
}

export default Goto;
