import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonVideoLanding, CampusStats, CertificatesStats, LoginStats, Qatar2022Link, ShellBoxAddon, Qatar2022Addon, Embajadores, ButtonChallengeBox, ButtonSecurityDay } from '..';

import { breakpoints } from '../../_helpers/breakpoints';
import { ContentContext } from '../../_context';
import { useLocation } from 'react-router-dom';
import { ButtonYellow } from '../../_elements';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    //background-color: black;

    //background-color: grey;
    /*
    @media ${breakpoints.sm} {
      width: 200px;
    }
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`


const DashboardStats = (props) => {

  const { sessionsStatData,
          coursesStatData,
          showMoreStats,
          userData } = useContext(ContentContext);

  const { search, pathname } = useLocation();
  const [ showAddon, setShowAddon ] = useState("");

  useEffect(() => {

    if(pathname){
      switch (pathname) {
        case "/challengebox":
          setShowAddon("challengebox");
          break;
        case "/qatar2022":
          setShowAddon("qatar2022");
          break;
        case "/shellbox":
          setShowAddon("shellbox");
          break;
        case "/dia-de-la-seguridad":
          setShowAddon("secutiryday");
          break;
        case "/trivia":
        case "/trivia/jugar":
        case "/trivia/ranking":
          setShowAddon("");
          break;
        case "/video-landing":
          setShowAddon("videolanding");
          break;
        default:
          setShowAddon("");
          //setShowAddon("shellbox");
          break;
      }
    }
    //storage.getItem('show_embajadores') == 'true' ?
    return () => { }
  }, [pathname])

  function inArray(needle, haystack) {
      var length = haystack.length;
      for(var i = 0; i < length; i++) {
          if(haystack[i] == needle) return true;
      }
      return false;
  }

  return (
    <Wrapper breakpoints={breakpoints}>
        {/*
        <TriviaStats />
        {userData && showButton(userData.dni) &&
          <Embajadores />
        }
        */}
        {/*<Encuesta />*/}
        {/*<PromoLink />*/}

        {/*<Qatar2022Link />*/}

        {/*showAddon != "challengebox" &&
          <ButtonChallengeBox />
        */}

        {/*showAddon != "secutiryday" &&
          <ButtonSecurityDay />
        */}

        {/*showAddon != "videolanding" &&
          <ButtonVideoLanding />
        */}

        <CampusStats data={coursesStatData} />
        <CertificatesStats props={props}/>
        <LoginStats data={sessionsStatData} />
        {showAddon == "shellbox" &&
          <ShellBoxAddon />
        }
        {showAddon == "qatar2022" &&
          <Qatar2022Addon />
        }
    </Wrapper>
  )

}

export default DashboardStats;
