import React from 'react';

import { ReactComponent as IcoComentaSVG } from '../_assets/images/icon_comentar.svg';
import { ReactComponent as IcoLikeOffSVG } from '../_assets/images/icon_like_off.svg';
import { ReactComponent as IcoLikeOnSVG } from '../_assets/images/icon_like_on.svg';
import { ReactComponent as SpinnerAnima } from '../_assets/images/loader_spinner.svg';
import { ReactComponent as LogoSVG } from '../_assets/images/logo.svg';
import { ReactComponent as IcoFelicitacionesSVG } from '../_assets/images/icon_felicitaciones.svg';
import { ReactComponent as IcoRespondeMalSVG } from '../_assets/images/icon_respondemal.svg';
import { ReactComponent as IcoMedal1SVG } from '../_assets/images/medal1.svg';
import { ReactComponent as IcoMedal2SVG } from '../_assets/images/medal2.svg';
//import { ReactComponent as IcoChevronRightSVG } from '../_assets/images/chevron_right.svg';
import { ReactComponent as IcoCheckSVG } from '../_assets/images/ico_check.svg';
import { ReactComponent as IcoChevronLeftSVG } from '../_assets/images/ico_chevron_left.svg';
import { ReactComponent as IcoChevronRightSVG } from '../_assets/images/ico_chevron_right.svg';
import { ReactComponent as IcoExitSVG } from '../_assets/images/ico_exit.svg';
import { ReactComponent as IcoPDFFileSVG } from '../_assets/images/ico_pdf_file.svg';

import { ReactComponent as ReactionAngrySVG } from '../_assets/images/icon_reaction_angry.svg';
import { ReactComponent as ReactionClapSVG } from '../_assets/images/icon_reaction_clap.svg';
import { ReactComponent as ReactionPartySVG } from '../_assets/images/icon_reaction_party.svg';
import { ReactComponent as ReactionSmileSVG } from '../_assets/images/icon_reaction_smile.svg';

export const ReactionAngry = (props) => { return ( <ReactionAngrySVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const ReactionClap = (props) => { return ( <ReactionClapSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const ReactionParty = (props) => { return ( <ReactionPartySVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const ReactionSmile = (props) => { return ( <ReactionSmileSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}


export const IcoDocument = (props) => { return ( <IcoComentaSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const IcoLikeOff = (props) => { return ( <IcoLikeOffSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const IcoLikeOn = (props) => { return ( <IcoLikeOnSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const Spinner = (props) => { return ( <SpinnerAnima fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const Logo = (props) => { return ( <LogoSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const IcoFelicitaciones = (props) => { return ( <IcoFelicitacionesSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const IcoRespondeMal = (props) => { return ( <IcoRespondeMalSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const IcoMedal1 = (props) => { return ( <IcoMedal1SVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const IcoMedal2 = (props) => { return ( <IcoMedal2SVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
//export const IcoChevronRight = (props) => { return ( <IcoChevronRightSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const IcoCheck = (props) => { return ( <IcoCheckSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const IcoChevronLeft = (props) => { return ( <IcoChevronLeftSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const IcoChevronRight = (props) => { return ( <IcoChevronRightSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const IcoExit = (props) => { return ( <IcoExitSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}
export const IcoPDFFile = (props) => { return ( <IcoPDFFileSVG fill={props.fill ? props.fill : "#000000"} className={props.className} /> )}

export const COLORS = {
  //primary: '#FFFFFF',
  //secondary: '#000000',
  primary: '#000000',
  secondary: '#FFFFFF',
  third: '#F5F6FA'
};